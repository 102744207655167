/**
* @description TheContentItem.vue
*/
<template>
<div>
  <div class="df-middle pl-16 pr-8 py-8 f-medium"
    @click="isShow" style="line-height: 24px;">
    <t as="p" class="flex-1" :path="accordion.title">{{accordion.title}}</t>
    <Icon
      :name="show ? 'caret-up' : 'caret-down'"
      class="ml-16" sm
    />
  </div>
  <div v-show="show" class="content px-16 pb-16 f-md c-title">
    <template v-if="isMultiProgram">
      <t :path="accordion.content[0]" multiline custom #="{td}">
        <p v-for="(p, index) in td" :key="index" :class="{'mt-12': index > 0}">
          {{p}}
        </p>
      </t>
    </template>
    <t v-else as="p" :path="accordion.content">{{accordion.content}}</t>
    <Pic v-if="accordion.image" :src="`/img/${accordion.image}@2x.png`" class="mt-12" />
  </div>
</div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from 'common/Pic.vue'

export default defineComponent({
  name: 'TheContentItem',
  components: { Pic, Icon },
  props: {
    accordion: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    const isMultiProgram = computed(() => {
      return Array.isArray(props.accordion.content)
    })
    return {
      isMultiProgram,
      show,
      isShow,
    }
  },
})
</script>

<style scoped lang="scss">
.content {
  line-height: 18px;

  img {
    width: 100%;
  }
}
</style>
