
import { deriveI18nPathFromJSON } from '@/pages/help/help'
import { ref } from 'vue'
import TheHelpItem from '@/pages/help/components/TheHelpItem.vue'
import list from '@/pages/help/help.json'
import { useRouter, useRoute } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Pic from 'common/Pic.vue'

export default {
  name: 'Help',
  components: { Pic, Tab, PageWithHeader, TheHelpItem },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const type = ref(route.query.type || 0)

    const derivedList = deriveI18nPathFromJSON(list)

    const lessons = [
      'What Is Fundamental Analysis',
      'Five Key Drivers of the Forex Markets',
      'Charting Basics – Bars vs. Candlesticks',
      'Understanding Technical Analysis',
      'What Is a Cryptocurrency?',
      'What is a pip ?',
      'What is Timeframe?',
    ]

    const switchTab = (index: number) => {
      type.value = index.toString()
      router.replace({ path: '/qa', query: { type: index } })
    }

    return {
      switchTab,
      type,
      lessons,
      list: derivedList,
    }
  },
}
