/**
* @description Help.vue
*/
<template>
  <PageWithHeader class="pt-12 px-16">
    <template #title>
      <t path="faq_2">FAQ and About Forex</t>
    </template>
    <!--    <router-link to="/" class="tips df-cross-center d-f py-12 f-bold">-->
    <!--      <div class="tips-text">4 Easy steps to make first trade</div>-->
    <!--    </router-link>-->
    <t #="{td, t}" custom>
      <Tab :tabs="['FAQ', 'Tutorial']"
        @switch="switchTab" :active="Number(type)" />
    </t>
    <div v-if="Number(type) === 1" class="py-16">
      <!-- <router-link
        v-for="(item, index) in lessons" v-once :key="index"
        tag="div" :to="`/common/link?title=${item}&url=/lesson.html?id=${index + 1}`"
        class="p-8 mb-8 df-middle faq-list"
      > -->
      <router-link
        v-for="(item, index) in lessons" v-once :key="index"
        tag="div" :to="`/help/lessons?index=${index}`"
        class="p-8 mb-8 df-middle faq-list"
      >
        <Pic :src="`/img/help/Block_${index + 1}@2x.png`" width="48" height="48" />
        <span class="flex-1 pl-16 df-middle f-medium">{{item}}</span>
      </router-link>
    </div>
    <TheHelpItem v-else v-for="item in list" v-once :key="item.type" :item="item" />
  </PageWithHeader>
</template>

<script lang="ts">
import { deriveI18nPathFromJSON } from '@/pages/help/help'
import { ref } from 'vue'
import TheHelpItem from '@/pages/help/components/TheHelpItem.vue'
import list from '@/pages/help/help.json'
import { useRouter, useRoute } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Pic from 'common/Pic.vue'

export default {
  name: 'Help',
  components: { Pic, Tab, PageWithHeader, TheHelpItem },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const type = ref(route.query.type || 0)

    const derivedList = deriveI18nPathFromJSON(list)

    const lessons = [
      'What Is Fundamental Analysis',
      'Five Key Drivers of the Forex Markets',
      'Charting Basics – Bars vs. Candlesticks',
      'Understanding Technical Analysis',
      'What Is a Cryptocurrency?',
      'What is a pip ?',
      'What is Timeframe?',
    ]

    const switchTab = (index: number) => {
      type.value = index.toString()
      router.replace({ path: '/qa', query: { type: index } })
    }

    return {
      switchTab,
      type,
      lessons,
      list: derivedList,
    }
  },
}
</script>

<style scoped lang="scss">
.faq-list {
  background: var(--color-light);
  border-radius: 2px;
}

.tips {
  justify-content: flex-end;
  background: url('/img/help/banner@2x.png') center no-repeat;
  background-size: 100% 100%;
  color: var(--color-white);
  line-height: 20px;
  min-height: 64px;
}

.tips-text {
  min-width: 60%;
  max-width: 60%;
}
</style>
