/**
* @description TheHelpItem.vue
*/
<template>
  <div class="pb-8 pt-16">
    <t as="p" :path="item.category" class="f-bold f-xl mb-16">{{item.category}}</t>
    <TheContentItem
      v-for="item in item.items" :key="item.index" :accordion="item"
      class="mb-8 item"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheContentItem from '@/pages/help/components/TheContentItem.vue'

export default defineComponent({
  name: 'TheHelpItem',
  components: { TheContentItem },
  props: {
    item: Object,
  },
})
</script>

<style scoped lang="scss">
  .item {
    border-radius: 2px;
    background: var(--color-theme);
  }

</style>
